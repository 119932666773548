// https://www.iubenda.com/en/help/1205-how-to-configure-your-cookie-solution-advanced-guide#:~:text=per%2Dcategory%20consent.-,purposes,-(string%2C%20default%20null
const IubendaCookiePurposes = Object.freeze({
  NECESSARY: 1,
  FUNCTIONALITY: 2,
  EXPERIENCE: 3,
  MEASUREMENT: 4,
  MARKETING: 5
})

export default IubendaCookiePurposes
