import Vue from 'vue'
import { productFruits } from 'product-fruits'
import IDENTITY from '@/queries/Identity.gql'
import { getInstance as getAuthInstance } from '../../auth/index'
import { getConsentCookie, getLatestIubendaCookie } from '@/lib/cookieHandling'
import IubendaCookiePurposes from '@/lib/enums/IubendaCookiePurposes'

const events = {
  VirtualPageView: 'VirtualPageView',
  Event: 'Event'
}

/**
 * Handles tracking of page views and events.
 * It considers Matomo, Google Tag Manager and Product Fruits.
 * The idea is to have a single point of tracking in the application.
 *
 * TODO: enrich events with additional data from the identity object. This must be integrated in a next iteration.
 * It's important to only send data to the tracking tools if the user has given consent. Otherwise only basic data must be sent.
 * Can the identity object passed through an initialize method? Or should the query be executed here separately?
 * Events should be sent only when those data are available.
 */
const useTracking = ({ apolloProvider }) => new Vue({
  data () {
    return {
      trackingEvents: {
        CLICKED: 'Clicked',
        SELECTED: 'Selected',
        GENERATED: 'Generated',
        LOADED: 'Loaded',
        CONNECTED: 'Connected',
        UPGRADED: 'Upgraded',
        DOWNGRADED: 'Downgraded',
        EDITED: 'Edited',
        GENERATION: 'Generation',
        UPLOAD: 'Upload'
      }
    }
  },
  methods: {
    /**
     * Tracks a page view. It uses the current route to determine the page title and URL.
     *
     * If `useActualPath` is set to true we'll use the path as shown in the url bar.
     * Means: if you have a route like `/products/:id` and in the url bar you see `/products/123`,
     * we'll use `/products/123`. Otherwise we'll use the route path `/products/:id`.
     * To set `useActualPath` add it to the route meta object like this:
     * ```js
     * meta: {
     *   tracking: {
     *     useActualPath: true
     *   }
     * }
     * ```
     *
     * @param {Object} route Vue Router route object (current page)
     */
    async pageView (route) {
      const { useActualPath = false } = route.meta?.tracking || {}
      const path = useActualPath ? route.path : route.matched[0].path
      const url = (new URL(path, window.location.origin)).toString()

      await this._push({
        event: events.VirtualPageView,
        title: route.name,
        url
      })
    },

    /**
     * Tracks an event in two ways:
     * - As a general event with the object and action as separate fields. It's primarily used for Matomo analysis.
     *   e.g. { event: 'Event', object: 'Button', action: 'Click', label: 'SignUp', value: '123' }
     * - As a specific event with the object and action as the event name
     *   e.g. { event: 'Button Click SignUp' }
     * This way in the tag manager we can use either depending on the use case.
     *
     * @param {string} object Event object name, e.g. 'Button', 'Form', 'Link', 'Topic'
     * @param {string} action Event action name, e.g. 'Click', 'Submit', 'Download', 'Created'
     * @param {string} [label=''] Event label, e.g. 'Sign Up', 'Download PDF' – label should be stable against changes, so better don't use translations
     * @param {string} [value=''] Event value, e.g. '123', 'PDF'
     */
    async event (object, action, label = '', value = '') {
      // object and action are required – let's warn the developer
      if ((!object || !action) && process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn('Tracking event requires object and action')
      }

      await this._push({
        event: events.Event,
        object,
        action,
        label,
        value
      })

      const composedEvent = `${object} ${action} ${label}`.trim()

      await this._push({
        event: composedEvent,
        value
      })

      productFruits.safeExec(() => {
        window.productFruits.api.events.track(composedEvent, {
          value
        })
      })
    },

    /**
     * Generic method to push data to the tracking tools.
     * @param {Object} data
     */
    async _push (data) {
      const hasTrackingConsent = getConsentCookie()?.includes('tracking') ||
        getLatestIubendaCookie()?.purposes?.[IubendaCookiePurposes.MEASUREMENT]
      const _mtm = window._mtm
      const dataLayer = window.dataLayer

      const authService = getAuthInstance()
      const isLoggedIn = authService?.user?.companyId
      const {
        data: {
          identity: {
            user: { id: userId, appLocale, email, role: { name: roleName } },
            company: { id: companyId, productType, country }
          }
        }
      } = isLoggedIn && hasTrackingConsent ? await apolloProvider.defaultClient.query({
        query: IDENTITY,
        fetchPolicy: 'cache-first',
        skip: !apolloProvider.defaultClient
      }) : { data: { identity: { user: { role: {} }, company: {} } } }

      const enhancedData = {
        ...data,
        user: { userId, appLocale, email, role: roleName },
        company: { companyId, productType, country }
      }

      if (_mtm) {
        _mtm.push(enhancedData)
      }

      if (dataLayer) {
        dataLayer.push(enhancedData)
      }

      if (process.env.VUE_APP_TRACK_CONSOLE === 'true') {
        // eslint-disable-next-line no-console
        console.log('Tracking:', enhancedData)
      }
    }
  }
})

export default {
  install (Vue, options) {
    Vue.prototype.$tracking = useTracking(options)
  }
}
